import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  useColorModeValue
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const Maintenance = ({ message }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <Box
      minH="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="gray.50"
    >
      <VStack
        spacing={6}
        bg={bgColor}
        p={8}
        borderRadius="lg"
        shadow="lg"
        maxW="md"
        w="full"
        textAlign="center"
      >
        <Heading size="xl">Under Maintenance</Heading>
        <Text fontSize="lg">{message}</Text>

        {user?.permissions?.includes("viewAdminOverview") && (
          <Button
            colorScheme="blue"
            onClick={() => navigate("/administration/overview")}
          >
            Go to Administration
          </Button>
        )}
      </VStack>
    </Box>
  );
};

export default Maintenance;
