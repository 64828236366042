import Navbar from "./Navbar";
import {
  IconDashboard,
  IconTable,
  IconList,
  IconCalendar,
  IconFileExport,
  IconTools,
  IconHome
} from "@tabler/icons-react";

const QualityControlNavbar = ({ children }) => {
  const linkItems = [
    {
      name: "Home",
      path: "/",
      icon: IconHome
    },
    {
      path: "/quality-control/",
      name: "Measuring",
      icon: IconTable
    }
  ];

  return <Navbar linkItems={linkItems}>{children}</Navbar>;
};

export default QualityControlNavbar;
