import { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import validateToken from "./services/auth";
import { Loader } from "./components/Loader";
import Maintenance from "./pages/Maintenance";
import ProgressBar from "./components/Shared/ProgressBar";
import MainNavbar from "./components/Layout/MainNavbar";
import InventoryNavbar from "./components/Layout/InventoryNavbar";
import SchedulingNavbar from "./components/Layout/SchedulingNavbar";
import QualityControlNavbar from "./components/Layout/QualityControlNavbar";
import { IndexRoutes } from "./routes/IndexRoutes";
import api from "./services/api";
import Footer from "./components/Layout/Footer";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);

  const { pathname } = location;
  const isPublicRoute = ["/login", "/register"].includes(pathname);
  const showNav = ![
    "/login",
    "/register",
    "/scheduling/daily-starts/totals"
  ].includes(pathname);

  useEffect(() => {
    const checkMaintenance = async () => {
      try {
        const { data } = await api("/api/health", "get");
        if (data?.maintenance) {
          setMaintenanceMessage(data.message);
        } else {
          setMaintenanceMessage(null);
        }
      } catch (error) {
        console.error(error);
      }
    };

    checkMaintenance();
  }, []);

  useEffect(() => {
    const validateAuth = async () => {
      try {
        if (isPublicRoute) {
          setIsLoading(false);
          return;
        }

        const response = await validateToken();
        if (response.authenticated) {
          dispatch({ type: "LOGIN", payload: { user: response.user } });
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error(error);
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    };

    validateAuth();
  }, [dispatch, navigate, pathname, isPublicRoute]);

  if (isLoading) {
    return <Loader />;
  }

  if (maintenanceMessage && !pathname.startsWith("/administration")) {
    return <Maintenance message={maintenanceMessage} />;
  }

  const renderLayout = (children) => {
    if (pathname.startsWith("/scheduling"))
      return <SchedulingNavbar>{children}</SchedulingNavbar>;
    if (pathname.startsWith("/inventory"))
      return <InventoryNavbar>{children}</InventoryNavbar>;
    if (pathname.startsWith("/quality-control"))
      return <QualityControlNavbar>{children}</QualityControlNavbar>;
    return <MainNavbar>{children}</MainNavbar>;
  };

  return (
    <>
      <ProgressBar />
      {showNav ? (
        renderLayout(
          <Routes>
            <Route path="*" element={<IndexRoutes />} />
          </Routes>
        )
      ) : (
        <Routes>
          <Route path="*" element={<IndexRoutes />} />
        </Routes>
      )}
    </>
  );
};

export { App };
