import { Route, Routes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { ApplicationGuard } from "../components/Guards/ApplicationGuard";

const Scripts = lazy(() => import("../pages/Scripts"));
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const Settings = lazy(() => import("./SettingRoutes"));
const Administration = lazy(() => import("./AdministrationRoutes.js"));
const Allmoxy = lazy(() => import("./AllmoxyRoutes"));
const EventLog = lazy(() => import("../pages/EventLog"));
const InventoryRoutes = lazy(() => import("./InventoryRoutes"));
const SchedulingRoutes = lazy(() => import("./SchedulingRoutes"));
const QualityControlRoutes = lazy(() => import("./QualityControlRoutes"));
const MainDashboard = lazy(() => import("../pages/Dashboard"));

const IndexRoutes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<MainDashboard />} />
        <Route
          path="/scripts"
          element={
            <ApplicationGuard applicationId="scripts">
              <Scripts />
            </ApplicationGuard>
          }
        />
        <Route
          path="/event-log"
          element={
            <ApplicationGuard applicationId="event-log">
              <EventLog />
            </ApplicationGuard>
          }
        />
        <Route path="/allmoxy/*" element={<Allmoxy />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/administration/*" element={<Administration />} />
        <Route path="/inventory/*" element={<InventoryRoutes />} />
        <Route path="/scheduling/*" element={<SchedulingRoutes />} />
        <Route path="/quality-control/*" element={<QualityControlRoutes />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Suspense>
  );
};

export { IndexRoutes };
