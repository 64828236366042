import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  FC
} from "react";
import { io, Socket } from "socket.io-client";
import { AuthContext } from "./AuthContext";
import { AuthContextType } from "./AuthContext";

interface WebSocketContextType {
  socket: Socket | null;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const useWebSocket = (): Socket | null => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context.socket;
};

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: FC<WebSocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const { isAuthenticated, user } = useContext(AuthContext) as AuthContextType;

  useEffect(() => {
    if (!isAuthenticated) return;

    const socket = io(`${process.env.REACT_APP_WEBSOCKET_URL}/clients`);

    socket.on("connect", () => {
      socket.emit("auth", user);
    });

    socket.on("maintenanceEnabled", () => {
      console.log("Maintenance enabled");
      window.location.reload();
    });

    socket.on("disconnect", (event: any) => {
      console.log("WebSocket Disconnected", event);
    });

    socket.on("forceReload", () => {
      console.log("Force reload received");
      window.location.reload();
    });

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, [isAuthenticated, user]);

  return (
    <WebSocketContext.Provider value={{ socket }}>
      {children}
    </WebSocketContext.Provider>
  );
};
