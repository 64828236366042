import React from "react";
import { Box } from "@chakra-ui/react";

const Footer = ({ frontendVersion, backendVersion }) => {
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      p={2}
      fontSize="12px"
      color="gray.600"
      bg="white"
      borderTop="1px solid"
      borderColor="gray.200"
      width="100%"
      textAlign="right"
      height="35px"
    >
      Frontend Version: {frontendVersion} | Backend Version: {backendVersion}
    </Box>
  );
};

export default Footer;
