import Navbar from "./Navbar";
import {
  IconDashboard,
  IconTable,
  IconSettings,
  IconClipboardList,
  IconScan,
  IconHome
} from "@tabler/icons-react";

const InventoryNavbar = ({ children }) => {
  const linkItems = [
    {
      name: "Home",
      path: "/",
      icon: IconHome
    },
    { path: "/inventory/", name: "Dashboard", icon: IconDashboard },
    { path: "/inventory/items", name: "View Inventory", icon: IconTable },
    {
      path: "/inventory/count",
      name: "Count Inventory",
      icon: IconClipboardList
    },
    { path: "/inventory/scanner", name: "Scanner", icon: IconScan },
    {
      path: "/inventory/settings",
      name: "Settings",
      icon: IconSettings,
      subItems: [
        { path: "/inventory/settings/types", name: "Inventory Types" },
        { path: "/inventory/settings/categories", name: "Categories" },
        { path: "/inventory/settings/locations", name: "Locations" },
        { path: "/inventory/settings/suppliers", name: "Suppliers" }
      ]
    }
  ];

  return <Navbar linkItems={linkItems}>{children}</Navbar>;
};

export default InventoryNavbar;
