import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../hooks/useAPI";
import { AuthContext } from "../../contexts/AuthContext";
import { useToast } from "@chakra-ui/react";

export const ApplicationGuard = ({ applicationId, children }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { data: applications } = useAPI("/api/administration/applications");
  const toast = useToast();

  useEffect(() => {
    const application = applications?.find((app) => app.id === applicationId);

    if (application) {
      const isEnabled = application.enabled;
      const hasAccess = user?.applications?.[applicationId] ?? isEnabled;

      if (!isEnabled) {
        navigate("/", { replace: true });
        toast({
          title: "Application Disabled",
          description: `The ${application.name} application is currently disabled`,
          status: "error",
          duration: 5000,
          isClosable: true
        });
      } else if (!hasAccess) {
        navigate("/", { replace: true });
        toast({
          title: "Access Denied",
          description: `You don't have access to ${application.name}`,
          status: "error",
          duration: 5000,
          isClosable: true
        });
      }
    }
  }, [applicationId, applications, user, navigate, toast]);

  return <>{children}</>;
};
